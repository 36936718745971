/* You can add global styles to this file, and also import other style files */
@use "fonts" as fonts;
@use 'src/assets/theme/variables' as variables;

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

// Default padding for each component
.container-padding {
  padding: 20px;
}

// Shared help icon
.help-icon {
  margin-left: 5px;
  font-size: 18px;
  color: variables.$neutral-60;

  &:hover {
    cursor: help;
  }
}

@media screen and (max-width: 960px) {
  .container-padding {
    padding: 20px;
  }
}

// New material Symbols filled
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

// Truncate long overflowing text in material list
.mat-mdc-list-item.mat-mdc-list-option {
  > .mdc-list-item__content > .mdc-list-item__primary-text {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}
