/* You can add global styles to this file, and also import other style files */
@use "scrollbar";
@use "fonts";

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

// Default padding for each component
.container-padding {
  padding: 40px;
}
@media screen and (max-width: 960px) {
  .container-padding {
    padding: 20px;
  }
}

// New material Symbols filled
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

// Truncate long overflowing text in material list
.mat-mdc-list-item.mat-mdc-list-option {
  > .mdc-list-item__content > .mdc-list-item__primary-text {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}

// Material override - for padding of h3 within dialog container
.mat-mdc-dialog-container {
  h3:first-of-type {
    margin-top: 0;
  }
}

// Material override - fixes line height and spacing of errors and hints
.mat-mdc-form-field-hint,
.mat-mdc-form-field-error {
  letter-spacing: normal;
  line-height: normal;
}

// Remove padding from the authentication dialog
// This needs to be here, as it is styling an entry component
//https://material.angular.io/guide/customizing-component-styles#styling-overlay-components
.app-no-padding-dialog .mat-mdc-dialog-container {
  padding: 0;
}

// Prevents Edge specific icon to show/hide password
// https://developer.mozilla.org/en-US/docs/Web/CSS/Microsoft_Extensions
input::-ms-reveal {
  display: none;
}
