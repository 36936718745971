
/* FONTS */

/* Roboto */
@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto/Roboto-Italic.woff2') format('woff2'),
      url('assets/fonts/roboto/Roboto-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto/Roboto-BoldItalic.woff2') format('woff2'),
      url('assets/fonts/roboto/Roboto-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto/Roboto-Bold.woff2') format('woff2'),
      url('assets/fonts/roboto/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('assets/fonts/roboto/Roboto-Regular.woff2') format('woff2'),
       local('Roboto'), url('assets/fonts/roboto/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


/* Material Icons / Symbols */
@font-face {
  font-family: 'Material Icons';
  src: url('assets/fonts/material/MaterialIcons-Regular.woff2') format('woff2'),
      url('assets/fonts/material/MaterialIcons-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Material Icons Outlined';
  src: url('assets/fonts/material/MaterialIconsOutlined-Regular.woff2') format('woff2'),
      url('assets/fonts/material/MaterialIconsOutlined-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400 600;
  src: url('assets/fonts/material/MaterialSymbolsOutlined.woff2') format('woff2');
  font-display: swap;
}


.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24;
}
