html:not(.macos):not(.mobile) {
  scrollbar-color: rgba(0, 2, 4, .2) transparent;
  scrollbar-width: thin;
}

html:not(.macos):not(.mobile) ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .2);
  background-clip: padding-box;
  border-left: 2px solid transparent !important;
  -webkit-border-radius: 4px;
  border-right: 2px solid transparent !important;
  min-height: 10px;
}

body div::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 0, 0, 0.3);
}

/**
  This appears to be necessary in order for the above CSS to apply to scrollable elements like divs.
 */
* {
  scrollbar-width: inherit;
}
